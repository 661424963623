/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 700;
  src: local('Gilroy-Bold ☞'), url('./assets/font/Gilroy-Bold.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 900;
  src: local('Gilroy-Heavy ☞'), url('./assets/font/Gilroy-Heavy.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 300;
  src: local('Gilroy-Light ☞'), url('./assets/font/Gilroy-Light.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 500;
  src: local('Gilroy-Medium ☞'), url('./assets/font/Gilroy-Medium.woff') format('woff');
}


@font-face {
  font-family: 'Gilroy';
  font-style: normal;
  font-weight: 400;
  src: local('Gilroy-Regular ☞'), url('./assets/font/Gilroy-Regular.woff') format('woff');
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gilroy';
  background-color: #000;
  /* background: #000 url("./assets/images/fullbg.png") no-repeat center; */
  background-size: cover;
  color: #ffffff;
  overflow-x: hidden;
}
.chart-width div{
  width: 100%;
}
a {
  color: #00ffcc;
  text-decoration: none;
}

a:hover {
  color: #ff437f;
  text-decoration: underline;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #FCD434;
}


.primary_btn {
  cursor: pointer;
  background: transparent;
  position: relative;
  display: inline-block;
  padding: 7px 30px;
  outline: none;
  /* -webkit-box-reflect: below 0px linear-gradient(transparent, #0002); */
  transition: 0.45s;
  transition-delay: 0s;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #FCD434;
  background: #000;
  z-index: 1;
}

.primary_btn:hover {
  transition-delay: .5s;
  color: #000;
  overflow: hidden;
}

.primary_btn span {
  position: relative;
  z-index: 100;
}

.primary_btn::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background: #FCD434;
  box-shadow: 5px -8px 0 #FCD434,
    5px 8px 0 #FCD434;
  transition: width 0.2s,
    left 0.2s,
    height 0.2s,
    box-shadow 0.2s;
  transition-delay: .3s, 0.2s, 0s, 0s;
  z-index: -1;
}

.primary_btn:hover::before {
  width: 60%;
  height: 100%;
  /* right: -2px; */
  left: 0px;
  box-shadow: 5px 0 0 #FCD434,
    5px 0 0 #FCD434;
  transition-delay: 0s, 0.2s, .3s, .3s;
}

.primary_btn::after {
  content: "";
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 2px;
  background: #FCD434;
  box-shadow: -5px -8px 0 #FCD434,
    -5px 8px 0 #FCD434;
  transition: width 0.2s, right 0.2s, height 0.2s, box-shadow 0.2s;
  transition-delay: .3s, 0.2s, 0s, 0s;
  z-index: -1;
}

.primary_btn:hover::after {
  width: 60%;
  height: 100%;
  right: 0px;
  box-shadow: -5px 0 0 #FCD434, -5px 0 0 #FCD434;
  transition-delay: 0s, 0.2s, .3s, .3s;
}

.para {
  font-size: 18px;
}

.h2tag {
  font-size: 48px;
  margin-bottom: 15px;
  font-weight: 700;
}
.mytag {
  margin-bottom: 15px;
  font-weight: 700;
}
.mytag span {
  color: #FCD434;
}
.h2tag span {
  color: #FCD434;
}

.head {
  max-width: 60%;
  text-align: center;
  margin: 0 auto 20px;
}

.overflow {
  overflow-x: hidden;
}

.page_header {
  text-align: center;
  padding: 70px 0;
  z-index: 1;
  position: relative;
}

.page_header::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 878px;
  background: url("./assets/images/bg.png") no-repeat center;
  /* background: url("./assets/images/banner_animation_06.gif") no-repeat center; */
  /* background: url("./assets/images/banner_animation_08.gif") no-repeat center; */

  background-size: 100%;
  background-size: 100%;
  left: 0;
  right: 0;
  top: 0px;
  z-index: -11;
}

/* .page_header::after {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50% 0 0 50%;
  width: 300px;
  height: 500px;
  bottom: -150px;
  margin: auto 0;
  z-index: -1;
  right: 0;
} */

.page_header h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  text-transform: capitalize;
}

.page_header p {
  max-width: 50%;
  margin: 0 auto;
}

.page_header img {
  z-index: 100;
  mix-blend-mode: difference;
  position: relative;
  margin-top: -90px;
}


.main_wrapper {
  margin-top: -50px;
}


.token {
  z-index: 1;
  position: relative;
  padding: 0 0 45px;

}

.token::before {
  content: "";
  position: absolute;
  background: url("./assets/images/chartbg.png") no-repeat center;
  top: 230px;
  left: 0;
  height: 1100px;
  width: 35%;
  z-index: -3;
  background-size: contain;
}

.token .head {
  max-width: 60%;
  padding-top: 50px;
  margin-bottom: 30px;
}

.token .box {
  text-align: center;
  position: relative;
  /* background: url("./assets/images/timerbg.png") no-repeat center; */
  padding: 40px 0;
}

.token .box::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(180deg, #18191A 0%, rgb(0, 0, 0) 32.81%);
  width: 100%;
  height: 170%;
  display: block;
  max-width: 85%;
  left: 0;
  right: 0;
  z-index: -1;
  margin: 0 auto;
}



.news {
  z-index: 1;
  position: relative;
  padding: 45px 0;
}

.news .head {
  max-width: 60%;
  margin-bottom: 50px;
}

.news .box {
  display: flex;
  border-radius: 10px;
  background: rgba(18, 18, 18, 0.75);
  padding: 30px;
  margin-top: 20px;
  transition: .5s;
}

.news .box:hover {
  transform: scale(1.03);
}

.news .box h5 {
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: 400;
}

.news .box p {
  color: #919191;
  font-size: 18px;
  margin-bottom: 0;
}

.news .box span {
  color: #919191;
  margin-bottom: 30px;
  display: block;
}

.news .box a {
  color: #FFF;
  font-size: 18px;
  text-decoration: underline;
  width: fit-content;
}

.news .box .row div:nth-child(2) {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.news .box .row div:nth-child(3) {
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}



.benefits {
  background: url("./assets/images/benefitbg.png") no-repeat center right;
  position: relative;
}

.benefits::before {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  bottom: -120px;
  z-index: -1;
  left: -230px;
}

.benefitbg {
  padding: 45px 0;
  background: linear-gradient(223deg, rgba(2, 2, 2, 0.00) 0%, #020202 100%);

}

.benefits .box {
  border-top: 2px solid #121212;
  padding: 15px 0;
  color: #919191;
  transition: .5s;
}

.benefits .box:hover {
  color: #fff;
}

.benefits .box img {
  opacity: .5;
}

.benefits .box:hover img {
  opacity: 1;
  transition: .5s;
}

.benefits .box:nth-last-child(1) {
  border-bottom: 2px solid #121212;
}

.benefits .box .num {
  min-width: 83px;
  height: 83px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #919191;
  margin-right: 40px;
  font-size: 22px;
  transition: .5s;
}

.benefits .box:hover .num {
  border: 1px solid #FCD434;
  color: #FCD434;
}

.benefits .box .row {
  align-items: center;
}

.benefits .box .row div:nth-child(1) {
  display: flex;
  align-items: center;
}

.benefits .box h5 {
  font-size: 32px;
  margin-bottom: 0;
}



.works {
  padding: 45px 0;
  position: relative;
  overflow-x: hidden;
}

.works::before {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  top: -120px;
  z-index: -1;
  right: -230px;
}

.works ul {
  list-style-type: none;
}

.works li {
  position: relative;
  font-size: 18px;
  margin-bottom: 50px;
}

.works li::before {
  content: "";
  position: absolute;
  border-radius: 20px;
  width: 15px;
  top: 5px;
  height: 30px;
  flex-shrink: 0;
  left: -30px;
  background: linear-gradient(180deg, #F4C01B 0%, rgba(244, 192, 27, 0.40) 59.50%, rgba(244, 192, 27, 0.00) 100%);
}

.works .img_box {
  position: relative;
  text-align: center;
}

.works .round {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  /* animation: spin 5s linear infinite; */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.packages {
  padding: 45px 0;
  background: url("./assets/images/package_bg.png") no-repeat center;
  position: relative;
  overflow-x: hidden;
}

.packages::after {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  top: 0%;
  bottom: 0;
  margin: auto 0;
  z-index: -1;
  left: -230px;
}

.packages::before {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  top: 0px;
  bottom: 0;
  margin: auto 0;
  z-index: -1;
  right: -230px;
}

.packages .box {
  border-radius: 10px;
  border: 1px solid #FCD434;
  font-size: 24px;
  padding: 15px 25px;
  text-align: center;
  margin-bottom: 45px;
  position: relative;
}

.packages .box::before {
  content: "";
  position: absolute;
  left: 100%;
  top: 42%;
  bottom: 0;
  background: url("./assets/images/top_line.png") no-repeat center;
  width: 330px;
  height: 119px;
}

.packages .box:nth-child(2)::before {
  background: linear-gradient(90deg, #F4C01B 0%, rgba(244, 192, 27, 0.00) 100%);
  height: 17px;
}

.packages .box:nth-last-child(1)::before {
  top: -59%;
  background: url("./assets/images/blw_line.png") no-repeat center;
}

.packages .right .box::before {
  right: 100%;
  left: unset;
  transform: rotateY(180deg);
}

.packages .box:nth-last-child(1) {
  margin-bottom: 0;
}

.packages .box h5 {
  font-weight: 500;
  margin-bottom: 0;
}

.packages .box h5:nth-child(1) {
  border-bottom: 1px solid #6B540A;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.packages .img_box {

  text-align: center;
  /* margin: auto; 
  position: relative;
  z-index: 2; */
}

.packages .img_box .circle {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
  animation: round 30s linear infinite;
}

@keyframes round {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.packages .col-lg-8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.packages .img_box .ball {
  background: linear-gradient(45deg, #fcd434, transparent);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  z-index: -1;
}

.packages .img_box .ball1 {
  right: 55.5%;
  top: 7.7%;
  animation: ball1 3s infinite;
}

@keyframes ball1 {
  0% {
    right: 55.5%;
    top: 35%;
  }

  50% {
    right: 55.5%;
    top: 7.7%;
    box-shadow: 0 0 20px 0 #fcd434;
  }

  80% {
    opacity: 1;
  }

  100% {
    right: 100%;
    top: 7.7%;
    box-shadow: 0 0 0px 0 #fcd434;
    opacity: 0;
  }
}

.packages .img_box .ball2 {
  right: 55.5%;
  top: 44%;
  animation: ball2 3s infinite;
}

@keyframes ball2 {
  0% {
    right: 55.5%;
  }

  50% {
    right: 55.5%;
    box-shadow: 0 0 20px 0 #fcd434;
  }

  80% {
    opacity: 1;
  }

  100% {
    right: 100%;
    box-shadow: 0 0 0px 0 #fcd434;
    opacity: 0;
  }
}

.packages .img_box .ball3 {
  right: 55.5%;
  top: 54%;
  animation: ball3 3s infinite;
}

@keyframes ball3 {
  0% {
    right: 55.5%;
    top: 54%;
  }

  50% {
    right: 55.5%;
    top: 82%;
    box-shadow: 0 0 20px 0 #fcd434;
  }

  80% {
    opacity: 1;
  }

  100% {
    right: 100%;
    top: 82%;
    box-shadow: 0 0 0px 0 #fcd434;
    opacity: 0;
  }
}

.packages .img_box .ball4 {
  right: 39%;
  top: 7.7%;
  animation: ball4 3s infinite;
}

@keyframes ball4 {
  0% {

    right: 39%;
    top: 35%;
  }

  50% {

    right: 39%;
    top: 7.7%;
    box-shadow: 0 0 20px 0 #fcd434;
  }

  80% {
    opacity: 1;
  }

  100% {
    right: 0%;
    top: 7.7%;
    box-shadow: 0 0 0px 0 #fcd434;
    opacity: 0;
  }
}

.packages .img_box .ball5 {
  right: 39%;
  top: 44%;
  animation: ball5 3s infinite;
}

@keyframes ball5 {
  0% {

    right: 39%;
  }

  50% {

    right: 39%;
    box-shadow: 0 0 20px 0 #fcd434;
  }

  80% {
    opacity: 1;
  }

  100% {
    right: 0%;
    box-shadow: 0 0 0px 0 #fcd434;
    opacity: 0;
  }
}

.packages .img_box .ball6 {
  right: 39%;
  top: 54%;
  animation: ball6 3s infinite;
}

@keyframes ball6 {
  0% {

    right: 39%;
    top: 54%;
  }

  50% {

    right: 39%;
    top: 82%;
    box-shadow: 0 0 20px 0 #fcd434;
  }

  80% {
    opacity: 1;
  }

  100% {
    right: 0%;
    top: 82%;
    box-shadow: 0 0 0px 0 #fcd434;
    opacity: 0;
  }
}



.how {
  background: url("./assets/images/how_bg.png") no-repeat center;
  background-color: #020202;
  padding: 55px 0;
  background-size: 100%;
  position: relative;
}

.how::before {
  content: "";
  background: url("./assets/images/chartbg.png") no-repeat center;
  position: absolute;
  width: 446px;
  height: 888px;
  z-index: -1;
  left: -50px;
  top: -100px;
  background-size: contain;
}

.how .box {
  padding-top: 10px;
  border-radius: 10px;
  background: #040404;
  display: flex;
  max-width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 25% 1fr;
}

.how .box .nav {
  flex-direction: column;
  border: 0;
  z-index: 0;
}

.how .box .nav li {
  height: 33.33%;
}

.how .box .nav-link {
  color: #919191;
  font-weight: 500;
  font-size: 28px;
  margin: 0;
  border: 1px solid transparent;
  width: 100%;
  height: 100%;
  position: relative;
}

.how .box .nav-link.active {
  background: transparent;
  border-radius: 10px 0 0 10px;
  color: #FCD434;
  margin: 0;
  border: 1px solid #FCD434;
  border-right: 0;
  margin-right: -10px;
}

.how .box .nav-link.active::before {
  content: "";
  position: absolute;
  background: #040404;
  border-top: 1px solid #FCD434;
  border-bottom: 1px solid #FCD434;
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: 15px;
}

.how .box .tab-pane {
  padding: 50px 60px;
  border: 1px solid #FCD434;
  border-radius: 10px !important;
}

.how .box .tab-pane:nth-child(1) {
  border-top-left-radius: 0 !important;
}

.how .box .tab-pane:nth-last-child(1) {
  border-bottom-left-radius: 0 !important;
  margin-bottom: 1px;
}

.how .tab-pane ul {
  list-style-type: none;
}

.how .tab-pane li {
  position: relative;
  font-size: 18px;
  margin-bottom: 20px;
}

.how .tab-pane li::before {
  content: "";
  position: absolute;
  border-radius: 20px;
  top: 5px;
  width: 10px;
  height: 20px;
  flex-shrink: 0;
  left: -30px;
  background: linear-gradient(180deg, #F4C01B 0%, rgba(244, 192, 27, 0.40) 59.50%, rgba(244, 192, 27, 0.00) 100%);
}



.supported {
  padding: 45px 0;
  position: relative;
}

.supported::before {
  content: "";
  position: absolute;
  background: url("./assets/images/security_right.png") no-repeat center;
  top: 40px;
  right: 0;
  height: 1168px;
  width: 546px;
  z-index: -3;
  background-size: contain;
  background-position: right;
}

.supported::after {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  top: 0%;
  bottom: 0;
  margin: auto 0;
  z-index: -1;
  left: -230px;
}

.supported .box {
  border-radius: 10px;
  background: linear-gradient(163deg, #121212 0%, #000 100%);
  text-align: center;
  padding: 15px;

}

.supported .box:hover {
  background: linear-gradient(304deg, #121212 0%, #000 100%);
  transition: .5s;
}

.supported .box img {
  border-radius: 50%;
  margin-top: -70px;
  margin-bottom: 15px;
}

.supported .box:hover img {
  /* transition: .5s;
  transform: rotateY(360deg); */
  animation: rotateY-anim 2s linear;
  animation-iteration-count: 1;
}

@keyframes rotateY-anim {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.supported .box h5 {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 0;
}

.supported .box p {
  color: #919191;
  font-size: 20px;
  margin-bottom: 0;
}



.security {
  padding: 45px 0 0;
}

.security .box {
  border-radius: 10px;
  background: linear-gradient(137deg, #121212 0%, #000 100%);
  padding: 30px 25px;
  width: 100%;
  transition: .5s;
}

.security .box:hover {
  transform: scale(1.03);
}

.security .box h5 {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 25px;
}

.security .box h5 span {
  color: #FCD434;
}

.security .box p {
  font-size: 18px;
  margin-bottom: 0;
}

.security .box:hover p {
  opacity: .5;
  transition: .5s;
}


.roadmap {
  position: relative;
  overflow-x: hidden;
}

.roadmap::before {
  content: "";
  position: absolute;
  background: url("./assets/images/roadmap_leftbg.png") no-repeat center;
  top: -150px;
  left: 0;
  height: 1068px;
  width: 546px;
  z-index: -3;
  background-size: contain;
}

.roadmap::after {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  top: 10%;
  bottom: 0;
  margin: auto 0;
  z-index: -1;
  right: -230px;
}

.roadmapbg {
  position: relative;
  overflow: hidden;
  padding: 90px 0 45px;
}

.roadmapbg::before {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50%;
  width: 400px;
  height: 400px;
  bottom: -100px;
  margin: auto 0;
  z-index: -1;
  left: -230px;
}

.roadmap .box {
  position: relative;
  padding-bottom: 80px;
  max-width: 80%;
  margin: 0 auto;
}

.roadmap .box:not(:nth-last-child(1)):before {
  content: "";
  border: 1px dashed #919191;
  left: 0;
  height: 100%;
  position: absolute;
}

.roadmap .box.ylw::before {
  border: 1px dashed #FCD434;
}

.roadmap .box::after {
  content: "";
  background: #919191;
  height: 24px;
  width: 24px;
  left: -11px;
  top: 0;
  position: absolute;
  border-radius: 50%;
}

.roadmap .box.ylw::after {
  background: #FCD434;
}

.roadmap .box span {
  font-size: 18px;
  margin-bottom: 10px;
  color: #919191;
}

.roadmap .box.ylw span {
  color: #FCD434;
}

.roadmap .box h5 {
  color: #FFF;
  margin-bottom: 0;
  font-size: 20px;
}

.roadmap .box p {
  font-size: 18px;
  margin-bottom: 0;
  color: #919191;
}


.launchpad {
  padding: 45px 0;
  background: linear-gradient(223deg, rgba(2, 2, 2, 0.00) 0%, #020202 100%);
  position: relative;
}

.launchpad::before {
  content: "";
  position: absolute;
  background: url("./assets/images/supported_right_bg.png") no-repeat center;
  bottom: 0;
  right: 0;
  height: 968px;
  width: 530px;
  z-index: -3;
  background-size: contain;
  background-position: right;

}

.launchpad .box img {
  margin-bottom: 20px;
}

.launchpad .box p {
  margin-bottom: 20px;
}

.launchpad .box a {
  text-decoration: underline;
  color: #fff;
  display: block;
  margin-top: 20px;
  width: fit-content;
}

.testimonial {
  padding: 45px 0;
  position: relative;
}

.testimonial::before {
  content: "";
  position: absolute;
  background: url("./assets/images/roadmap_leftbg.png") no-repeat center;
  top: -50px;
  left: 0;
  height: 968px;
  width: 546px;
  z-index: -3;
  background-size: contain;
  background-position: left;
}

.testimonial .box {
  text-align: center;
  max-width: 55%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial .box .para {
  margin: 30px 0;
}

.testimonial .boxes h5 {
  font-size: 22px;
  margin-bottom: 0;
}

.testimonial .boxes span {
  color: #919191;
}



.faq {
  padding: 45px 0;
  background: linear-gradient(223deg, rgba(2, 2, 2, 0.00) 0%, #020202 100%);
}

.faq .accordion-item {
  box-shadow: none !important;
  border: 0;
  border-bottom: 1px solid #121212;
  background: transparent;
  border-radius: 0;
}

.faq .accordion-item button {
  color: #FFF;
  font-weight: 500;
  font-size: 24px;
  background: transparent;
  box-shadow: none !important;
}

.faq .para {
  color: #919191;
  font-size: 18px;
}

.faq .accordion-button.collapsed::after {
  background: url("./assets/images/plus.png") no-repeat center;
  width: 45px;
  height: 45px;
}

.faq .accordion-button:not(.collapsed)::after {
  background: url("./assets/images/minus.png") no-repeat center;
  width: 45px;
  height: 45px;
}


.updates {
  padding: 45px 0;
  position: relative;
}

.updates::after {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  top: 0%;
  bottom: 0;
  margin: auto 0;
  z-index: -1;
  left: -230px;
}

.updates::before {
  content: "";
  position: absolute;
  background: url("./assets/images/supported_right_bg.png") no-repeat center;
  bottom: -150px;
  right: 0;
  height: 768px;
  width: 530px;
  z-index: -3;

  background-size: contain;
  background-position: right;

}

.updates .input-group {
  border-radius: 10px;
  border: 1px solid #121212;
  background: #000;
}

.updates input {
  box-shadow: none !important;
  background: transparent !important;
  border: 0;
  color: #fff !important;
  padding-left: 0;
  padding-right: 0;
}

.updates .input-group-text {
  background: transparent;
  border: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}

.updates .subscribe {
  background: #FCD434;
  padding: 10px;
  width: 150px;
  color: #000;
  font-weight: 500;
  border-radius: 8px;
  height: 45px;
}


.halfbg {
  background: url("./assets/images/half_bg.png") no-repeat center;
}



















/* timer  */
.timer_panel {
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  margin: 0 auto 70px;
  padding: 0 100px;
}

.timer_panel>span:nth-child(odd) {
  border-radius: 10px;
  border: 1px solid #121212;
  background: linear-gradient(141deg, #101010 33.33%, #000 76.88%);
  padding: 0px 20px 15px;
  position: relative;
  min-width: 110px;
}

.timer_panel>span:nth-child(odd)::before {
  content: "";
  background: #121212;
  width: 100%;
  height: 2px;
  right: 0px;
  left: 0;
  top: 41px;
  position: absolute;
}

.timer_time {
  font-size: 48px;
  text-align: center;
  font-weight: 700;
  color: #ffffff;
}

span.timer_label {
  display: block;
  text-align: center;
  font-size: 13px;
  margin-top: -14px;
  font-weight: 400;
  color: #919191;
}

span.timer_label.sec {
  text-align: left;
  padding: 0 5px;
}

span.timer_dots {
  font-size: 48px;
  color: #fff;
  /* line-height: 82px; */
  font-weight: 700;
}

.timer_panel>span {
  margin: 0px 15px 0 0;
}

/* ends timer  */



.main_navbar {
  background: #020202;
  z-index: 1000;
}

.navbar-brand {
  outline: 0 !important;
  padding: 0;
}
.navbar-toggler{
  box-shadow: none !important;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #0e0d0d;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  z-index: 1000;
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.offcanvas {
  background: #050505;
}
.navbar_right{
  display: flex;
  align-items: center;
}
.navbar_right .dropdown .dropdown-toggle{
  padding: 7px 30px;
  color: #fff;
  text-decoration: none;
}
.navbar_right .dropdown .dropdown-toggle::after {
 border: 0;
}
.navbar_right .dropdown img{
  margin-right: 10px;
  margin-top: -2px;
}
.navbar_right .dropdown-menu{
  background: #0f0e0e;
} 
.navbar_right .dropdown-item{
  color: #fff;
  background: #0f0e0e !important;
}
.navbar_right .primary_btn {
  font-weight: 600;
}
.navbar_right .primary_btn:hover{
  overflow: visible;
}
.btn-close {
  filter: invert(1);
  box-shadow: none !important;
}

.footer{
position: relative;
z-index: 1;
}
.footer_panel {
  border-bottom: 1px solid #121212;
  padding-bottom: 30px;
}

.footer_panel ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer_panel p,
.footer_panel li {
  color: #919191;
  font-size: 18px;
  margin-bottom: 20px;
}

.footer_panel h5 {
  color: #FFF;
  margin-bottom: 15px;
  font-size: 20px;
}

.footer {
  background: #020202;
  padding-top: 40px;
}

.footer .ylw {
  color: #FCD434;
  margin-bottom: 0;
  word-break: break-word;
}

.footer_panel .sm {
  font-size: 16px;
}

.footer_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.footer_flx p {
  color: #919191;
  font-size: 18px;
  margin-bottom: 0;
}

.footer_social_links {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.footer_social_links li a {
  background: #919191;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  margin-left: 10px;
  text-decoration: none !important;
}

.footer_social_links li a:hover {
  background: #FCD434;
}


/* Connect Wallet */
.primary_modal .modal-content {
  background: linear-gradient(132deg, #0a0a0b, #000000);
}

.primary_modal .modal-header {
  border-bottom: 1px solid #413e3f;
  padding: 20px 24px 15px;
}

.connect_wallet_button {
  border: 2px solid #413e3f;
  background-color: transparent;
  color: #fff;
  border-radius: 23px;
  padding: 0 24px;
  outline: 0;
  line-height: 46px;
  height: 52px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}

.primary_modal .btn-close {
  filter: invert(1);
}

.connect_wallet_button:hover {
  border-color: #fcd434c2;
}

.primary_modal .modal-header h5 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #ffffff;
}

.connect_wallet_button img {
  margin-right: 15px;
}

.connect_wallet_button span {
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
}

.btn-close {
  width: 32px;
  height: 32px;
  color: #000;
  border: 0;
  /* background: transparent url("../src/assets/images/close_icon.png") no-repeat; */
  opacity: 0.8;
  outline: none;
  box-shadow: none !important;
}

.btn-close:hover {
  opacity: 1;
}

.primary_modal a {
  text-decoration: none;
}

.dashboard {
  position: relative;
  padding-top: 60px;
  padding-bottom: 100px;
  z-index: 1;
  overflow: hidden;
}

.dashboard::before {
  content: "";
  position: absolute;
  background: url("./assets/images/roadmap_leftbg.png") no-repeat center;
  left: 0;
  top: -190px;
  height: 1074px;
  width: 542px;
  z-index: -1;
}
.dashboard::after {
  content: "";
  position: absolute;
  background: url("./assets/images/security_right.png") no-repeat center;
  top: 40%;
  right: 0;
  height: 1168px;
  width: 546px;
  z-index: -3;
  background-size: contain;
  background-position: right;
}
.dashboard_box{
  position: relative;
}
.dashboard_box::before {
  content: "";
  position: absolute;
  background: #060606;
  filter: blur(32px);
  border-radius: 50%;
  width: 500px;
  height: 500px;
  top: 11%; 
  margin: auto 0;
  z-index: -1;
  right: -230px;
}
.dashboard_box::after {
  content: "";
  position: absolute;
  background: #060606;
  background: url("./assets/images/roadmap_leftbg.png") no-repeat center;
  width: 30%;
  height: 820px;
  bottom: -450px;
  margin: auto 0;
  z-index: -1;
  left: 0;
  background-size: contain;
  background-position: left;
}
.dashboard .table-responsive {
  height: 284px;
}

.dashboard .h2tag {
  font-size: 40px;
}

.dashboard .head_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.dashboard .head_flx .wallet {
  background: #fff0;
  box-shadow: none !important;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #626262;
  border-radius: 10px;
  border: 1px solid #121212;
}

.dashboard .head_flx .wallet img {
  margin-top: -2px;
  cursor: pointer;
}

.dashboard .head_flx .wallet img:hover,
.dashboard .box .link .copy:hover {
  filter: invert(1) brightness(36.5);
}

.dashboard .box {
  border-radius: 10px;
  border: 1px solid #121212;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  background: linear-gradient(141deg, #101010 33.33%, #000 76.88%);
}

.dashboard .box h5 {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 0;
}

.dashboard .box p {
  color: #919191;
  margin-bottom: 0;
}

.dashboard .box .sm {
  color: #fff;
  font-weight: 500;
}

.dashboard .box .ylw {
  color: #FCD434;
}

.dashboard .box .link {
  box-shadow: none;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-radius: 5px;
  border: 1px solid #121212;
  background: #000;
}

.dashboard .box .link .half {
  width: 83%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #919191;
}

.dashboard .box .link .copy {
  cursor: pointer;
}

.dashboard .box ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.dashboard .box ul a {
  background: #919191;
  color: #000000 !important;
  text-decoration: none !important;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 8px;
  transition: .4s;
}

.dashboard .box ul a:hover {
  background: #FCD434;
}

.dashboard .button {
  background: #F4C01B;
  padding: 8px 25px;
  border-radius: 8px;
  color: #000;
  font-weight: 500;
  cursor: pointer;
}

.dashboard h4 {
  color: #FFF;
  font-size: 36px;
  font-weight: 700;
}

.dashboard .ylw {
  color: #FCD434;
}

.dashboard .table_box {
  border-radius: 10px;
  border: 1px solid #121212;
  background: rgba(0, 0, 0, 0.50);
  padding: 25px;
  padding-bottom: 0;
}

.dashboard .table-striped>tbody>tr:nth-of-type(odd)>* {
  background-color: transparent;
}

.dashboard .table>:not(caption)>*>* {
  background-color: transparent;
  color: #FFF;
  font-size: 18px;
  border-bottom: 2px solid #121212;
  white-space: nowrap;
}

.dashboard .table tr:nth-last-child(1) td {
  border: 0;
}

.dashboard .table th {
  color: #919191;
  background: transparent;
  font-size: 18px;
  font-weight: 400;
  white-space: nowrap;
}
.dashboard .bdr{
  border-radius: 10px;
border: 1px solid #121212;
background: rgba(0, 0, 0, 0.50);
background: #000;
padding: 20px;
overflow: hidden;
}
.dashboard .input-group {
  border-radius: 10px;
  border: 1px solid #121212;
  background: #000;
  overflow: hidden;
}

.dashboard input {
  border-radius: 28px;
  background: transparent !important;
  border: 0 !important;
  padding: 10px 15px;
  box-shadow: none !important;
}

.dashboard .input-group-text {
  background: #000;
  border: 0;
}

.dashboard .treeview {
  color: #000;
  overflow: visible !important;
  position: relative;
  padding-top: 30px !important;
}

.dashboard .treeview i {
  font-size: 20px;
  color: #F4C01B;
}

.dashboard .treeview .id {
  color: #fff; 
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

/* .dashboard .treeview .blur {
  opacity: .4;
} */
.dashboard .treeview svg{
  width: 30px !important;
  height: 30px !important;
  margin-left: -8px;
}
.dashboard .treeview div:not(.treeview) {
  padding-left: 30px !important;
  border-color: #121212 !important;
  border-left-style: solid !important;
}

/* before line on open close icons */
/* .dashboard .treeview .treeview::before {
  content: "";
  position: absolute;
  width: 30px;
  left: -30px;
  height: 1px;
  background: #121212;
  top: 43px;
} */

/* hide last user line */
.dashboard .treeview .treeview:nth-last-child(1)::before {
  content: "";
  position: absolute;
  width: 20px;
  left: -31px;
  height: 100%;
  background: #000;
  top: 43px;
  /* border-top: 1px solid #121212; */
}

/* .dashboard .treeview div:not(.treeview)::before{
  content: "";
  background: #aa9292;
  height: calc(50% - 20px);
  width: 15px;
  display: block;
  left: -9px;
  position: absolute;
  bottom: 0;
} */
/* .dashboard .treeview div:not(.treeview)::before {
  content: "";
  background: #9f7f7f;
  height: calc(50% - 20px);
  width: 1px;
  display: block;
  left: -1px;
  position: absolute;
  top: 0;
} */
.tree_flx{
  display: flex; 
  justify-content: space-between;
}
.tree_flx .box{
  width: fit-content;
  display: block;
  height: fit-content;
  margin-top: 30px;
}
.tree_flx .box i{
  font-size: 20px;
  color: #F4C01B;
  margin-right: 8px;
}
.joinnow{
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.joinnow::before {
  content: "";
  position: absolute;
  background: url("./assets/images/roadmap_leftbg.png") no-repeat center;
  left: 0;
  top: -190px;
  height: 1074px;
  width: 542px;
  z-index: -1;
}
.joinnow::after {
  content: "";
  position: absolute;
  background: #060606;
  background: url("./assets/images/roadmap_leftbg.png") no-repeat center;
  width: 30%;
  height: 820px;
  bottom: -450px;
  margin: auto 0;
  z-index: -1;
  left: 0;
  background-size: contain;
  background-position: left;
}
.joinnow_bg{
  position: relative;
  z-index: 1;
}
.joinnow_bg::after {
  content: "";
  position: absolute;
  background: url("./assets/images/security_right.png") no-repeat center;
  top: -10%;
  right: -20px;
  height: 1168px;
  width: 546px;
  z-index: -3;
  background-size: contain;
  background-position: right;
}
.joinnow .head h2{
 font-size: 40px; 
}
.joinnow .head p span{
  color: #F4C01B;
}
.joinnow .whole_box{
  margin-bottom: 25px;
  border-radius: 10px;
  padding: 10px;
 border: 1px solid #121212;
 background: linear-gradient(141deg, #101010 33.33%, #000 76.88%);
}
.joinnow .box{
 display: flex;
 align-items: center;
 justify-content: space-between;
width: 100%;
padding: 25px;
}
.joinnow .box>div{
  display: flex;
  align-items: center;
}
.joinnow .num{
  background: url("./assets/images/numbg.png") no-repeat center;
  width: 43px;
height: 45px;
color: #000;
font-weight: 500;
font-size: 26px;
display: flex;
align-items: center;
justify-content: center;
background-size: contain;
}
.joinnow .box p{
  font-size: 18px;
  color: #fff;
  margin-bottom: 0;
  margin-left: 15px;
  font-weight: 500;
}
.joinnow .box h5{
  color: #F4C01B; 
font-size: 26px;
margin-bottom: 0;
font-weight: 600;
}
.joinnow .box span{
  color: #919191;
  margin-left: 15px;
  font-size: 13px;
  margin-bottom: 0;
}
.joinnow .box .butn{
  background: #F4C01B;
  color: #000;
  box-shadow: none;
  width: 150px;
height: 45px;
border: 0;
border-radius: 8px;
text-align: center;
font-weight: 600;
padding: 8px;
}
.joinnow  .primary_btn{
  white-space: nowrap;
  width: 130px !important;
}
.joinnow .box .active{
  background: #919191;
}
.joinnow input{
  border-radius: 10px;
  border: 1px solid #fcd434!important;
  background: #000 !important;
  box-shadow: none !important;
  padding: 10px;
}
.joinnow .blw_box {
  padding: 50px 100px;
  border-top: 1px solid #6B540A;
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 0.7fr;
  gap: 40px;  
  transition: 1s;  
}

.dashboard .nav {
border-bottom:2px solid #121212 ;
}
.dashboard .nav-link{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding-left: 0;
  border: 0 !important;
  background: linear-gradient(45deg, #0b0b0b, black);
  padding: 8px 15px;
  border-radius: 8px ;
  margin-bottom: 10px;
  margin-right: 20px;

}
.dashboard .nav-link.active ,
.dashboard .nav-link:hover {
  background: linear-gradient(45deg, #0b0b0b, black);
  color: #FCD434;
}

.join_modal  .modal-content{
  max-width: 650px;
  padding: 0 15px;
}
.join_modal .modal-body{
  padding: 0;
}


.rdt_Table{
  background: #000;
  color: #fff;
}
.rdt_Table p{
  color: #fff !important;
}
.rdt_Table>div{
  background-color: #000 !important;
  color:#fff;
}
.tabledeatils .rdt_TableHeadRow {
background: #101010 !important;
  color: #d1d1d1;
  font-size: 15px;
  font-weight: 600; 
}

.tabledeatils .rdt_TableRow {
  background-color: #000;
  color: #fff;
}
.tabledeatils .rdt_TableRow a{
  color: #fff;
  text-decoration: none;
}
.tabledeatils  .rdt_Pagination{
  background: #000;
  color: #fff;
}
.tabledeatils  .rdt_Pagination button{  
  fill: rgb(255 255 255 / 54%);
}
.tabledeatils  .rdt_Pagination button:disabled {
opacity:0.5;
}
.tabledeatils  .rdt_Pagination select{
  background: #0f0f0f;
}
.tabledeatils  .rdt_Pagination option{  
  background: #0f0f0f;
  color: #fff;
}
.tabledeatils  .rdt_Pagination option[selected]{
  background: #0f0f0f !important;
}
.blog_detail_sec {
  background-color: rgba(18, 18, 18, 0.75);
  background: url("../src/assets/images/how_bg.png") no-repeat 50% bottom;
  /* background-color: #020202; */
  border-radius: 10px;  
  margin: 100px 0;
  padding: 30px;  
  position: relative;
}
.blog_detail_sec h2,
.blog_detail_sec h5 {
  text-transform: capitalize;
}
.disable {
  opacity: .7;
  cursor: default !important;
}
.ticmark{
  height: 60px;
  margin: 30px auto;
  display: flex;
}
.iVexjf{
  background: #000 !important;
  color:  #fff !important;
}
.news_detail_header img {
  height: auto;
  margin: 0 auto 30px;
  object-fit: cover;
  width: 100%;
}

.myinput{
  border-radius: 10px;
  border: 1px solid #fcd434!important;
  background: #000 !important;
  box-shadow: none !important;
  padding: 10px;
}


@media (max-width:1399px) {
  .dashboard .right .box{
    flex-direction: column;
    align-items: flex-start;
  }
  .dashboard .button{
    margin-top: 10px;
  }
  .dashboard .box h5{
    font-size: 24px;
  }
}

@media (max-width:1199px) {
  .footer_panel .col-xl-2 {
    margin-top: 25px;
  }
  .joinnow .blw_box{
    padding: 50px;
  }
  .how {
    background-image: none;
  }

  .packages .box {
    padding: 15px;
  }

  .page_header::after {
    display: none;
  }

  .timer_panel {
    padding: 0;
  }
}

@media (max-width:991px) {
  .join_modal  .modal-dialog {
    max-width: 700px;
  }
  .navbar_right{
    flex-direction: column;
  }
  .dashboard .input-group{
    width: 100% !important;
    margin-top: 10px;
  }
  .dashboard .box.mb-0 {
    margin-bottom: 25px !important;
  }
  .dashboard .head_flx{
    flex-direction: column;
  }
  .dashboard .head_flx .wallet{
    margin-top: 10px;
  }
  .packages .img_box .ball {
    display: none;
  }

  .how .box .tab-pane,
  .how .box .tab-pane:nth-child(1),
  .how .box .tab-pane:nth-last-child(1) {
    border-radius: 10px !important;
  }

  .timer_panel {
    padding: 0 120px 30px;
  }

  .timer_panel::before {
    display: none;
  }

  .h2tag {
    font-size: 40px;
  }

  .head {
    max-width: 100% !important;
  }

  .navbar_right {
    text-align: center;
  }

  .navbar_right .primary_btn {
    margin: 10px 0 !important;
  }

  .faq .accordion-item button {
    font-size: 22px;
  }

  .token .box {
    background: linear-gradient(180deg, #18191A, rgb(0, 0, 0));
    border-radius: 10px;
    padding: 20px;
  }

  .token .head {
    padding: 10px;
  }

  .token .box::before {
    display: none;
  }

  .news .box img {
    margin-bottom: 30px;
  }

  .benefits .box span {
    margin-top: 20px;
    display: block;
  }

  .benefits .box img {
    display: none;
  }

  .packages .img_box img {
    display: none;
  }

  .packages .box {
    margin-bottom: 15px !important;
  }

  .packages .box::before {
    display: none;
  }

  .how .box {
    display: block;
  }

  .how .box .nav {
    flex-direction: row;
    width: 100%;
  }

  .how .box .nav li {
    height: fit-content;
    width: 33.333%;
    margin-bottom: 10px;
  }

  .how .box .nav-link.active {
    border-radius: 10px;
    border: 1px solid #FCD434;
  }

  .how .box .nav-link.active::before {
    display: none;
  }

  .supported .box {
    margin-bottom: 75px;
  }

  .supported .box img {
    height: 70px;
  }

  .security .box {
    margin-bottom: 15px;
  }

  .roadmap .box {
    max-width: 100%;
    padding-left: 30px;
    padding-bottom: 50px;
  }

  .roadmap .box h5 {
    margin-bottom: 20px;
  }

  .launchpad .col-lg-4 {
    margin-bottom: 25px;
  }

  .testimonial .box {
    max-width: 100%;
  }

  .faq .accordion-button.collapsed::after,
  .faq .accordion-button:not(.collapsed)::after {
    width: 32px;
    height: 32px;
    background-size: contain;
  }

  .footer_flx {
    flex-direction: column;
  }

  .footer_social_links li a {
    margin: 10px 5px 10px;
  }
}

@media (max-width:767px) {
  .tree_flx{
    flex-direction: column-reverse
  }
  .joinnow .box {
    flex-direction: column;
    align-items: flex-start;
  }
  .joinnow .box>div{
    margin-bottom: 15px;
  }
  .joinnow .blw_box {
    padding: 30px 20px;
    grid-template-columns: 1fr;
}
  .dashboard .h2tag {
    font-size: 30px;
}
  .dashboard h4 {
    font-size: 24px;
  }
  .dashboard .box h5 {
    font-size: 20px;
 }
  .page_header h1 {
    font-size: 35px;
  }

  .page_header p {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .token .head {
    margin-bottom: 0;
  }

  .token img {
    margin-bottom: 20px;
  }

  .timer_time {
    font-size: 28px;
  }

  span.timer_label {
    margin-top: -10px;
  }

  .timer_panel>span:nth-child(odd)::before {
    top: 23px;
  }

  span.timer_dots {
    font-size: 38px;
  }

  .timer_panel>span:nth-child(odd) {
    padding: 0 15px 10px;
    min-width: 80px;
  }

  .how .box .tab-pane {
    padding: 20px;
  }
}

@media (max-width:575px) {
  .page_header h1 {
    font-size: 28px;
    font-weight: 900;
}
  .dashboard .nav-link {
    margin-right: 10px;
  }
  .tree {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .h2tag {
    font-size: 30px;
  }
  .joinnow .blw_box {
    padding: 20px;
  }
  .joinnow .box{
    padding: 15px;
  }

  .launchpad {
    max-width: 96%;
    margin: 0 auto;
  }

  .roadmap .container {
    padding-left: 22px;
    padding-right: 22px;
  }

  .how .box {
    max-width: 100%;
  }

  .how .box .nav-link {
    padding: 10px;
    font-size: 24px;
  }

  section {
    overflow: hidden !important;
    padding: 30px 0 !important;
  }

  .faq .accordion-item button {
    font-size: 20px;
  }

  section::before {
    width: 50% !important;
  }

  .page_header p {
    margin-bottom: 50px;
  }

  span.timer_label {
    font-size: 10px;
    margin-top: -7px;
  }

  .timer_time {
    font-size: 20px;
  }

  span.timer_dots {
    font-size: 26px;
  }

  .timer_panel>span {
    margin: 0px 4px 0 0;
  }

  .timer_panel>span:nth-child(odd) {
    padding: 0 5px 5px;
    min-width: 60px;
    border-radius: 5px;
  }

  .timer_panel>span:nth-child(odd)::before {
    top: 17px;
    height: 1px;
  }
}
